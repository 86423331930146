@import "../core/variables/variables";
@import "../core/variables/app-variables";
@import "../core/colors/palette-variables";

.auth-page {
  .auth-img {
    padding: 0;

    .auth-img-bg {
      background-color: map-get($grey-color, lighten-4);
    }
  }
}

// to remove scrollbar in auth pages
.auth-height {
  overflow: auto;
}
